import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Link from "gatsby-plugin-transition-link";
import { GatsbyImage } from "gatsby-plugin-image";

import GraphQLErrorList from "../Blog/graphql-error-list";
import {
  mapEdgesToNodes,
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
} from "../../lib/helpers";

const RecentPosts = ({ node, className }) => {
  const data = useStaticQuery(graphql`
    {
      posts: allSanityBlogs(
        sort: { fields: [publishedAt], order: DESC }
        filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
        limit: 3
      ) {
        edges {
          node {
            id
            publishedAt
            featuredImage {
              asset {
                gatsbyImageData
              }
            }
            categories {
              _id
              title
            }
            title
            slug {
              current
            }
          }
        }
      }
    }
  `);

  const errors = node;

  if (errors) {
    return <GraphQLErrorList errors={errors} />;
  }

  const blogNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  return (
    <>
      <section className={` ${className || "mb-20 md:mb-32"}`}>
        <div className="container">
          <div className="grid gap-y-10 md:grid-cols-3 md:gap-x-10">
            {blogNodes.length > 0 &&
              blogNodes.map((node) => (
                <div key={node.id}>
                  <Link
                    to={`/${node.slug.current}/`}
                    className="group block font-normal text-typography-body no-underline hover:text-typography-body"
                  >
                    {node.featuredImage && node.featuredImage.asset && (
                      <div className="mb-5 max-h-64 overflow-hidden rounded-xl">
                        <GatsbyImage
                          image={node.featuredImage.asset.gatsbyImageData}
                          loading="lazy"
                          className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                        />
                      </div>
                    )}
                    <div className="mb-1 flex flex-wrap items-center font-body text-sm font-medium uppercase tracking-wide text-primary-900/40 md:flex-nowrap">
                      {node.categories && (
                        <ul className="flex flex-wrap md:flex-nowrap">
                          {node.categories.map((category, i) => (
                            <li
                              className="mb-2 text-sm font-bold uppercase text-primary-500"
                              key={i}
                            >
                              {i > 0 ? <>, {category.title}</> : category.title}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                    <div className="heading-four mb-0">{node.title}</div>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default RecentPosts;
